@import '../../style/variables';

#deckSubmissionForm {
	font-size: calc(14px + 2vmin);
	width: 80%;

	#error {
		align-self: center;
		background-color: $offwhitetext;
		border-radius: 5px;
		border: solid 1px $bloodorange;
		color: $darkblue;
		font-size: 12px;
		margin-top: 10px;
		padding: 6px;
		text-align: center;
		width: 60%;

		&:empty {
			display: none;
		}
	}
}

form {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;

	// This is the individual blocks containing deck information
	> div {
		background-color: rgba(0, 0, 0, .3);
		border-radius: 7px;
		margin-bottom: 7px;
		padding: 10px;
		width: 100%;

		// In theory we could move almost all of this styling to
		// the InputBox.scss however that might be overly aggressive
		// on styling what is meant to be a generic component. In a
		// bigger project we could still use a designer's style guide
		// to populate the InputBox.scss with site standards.
		label,
		input {
			color: $offwhitetext;
			display: flex;
			flex-direction: column;
			font-size: 12px;
			padding-bottom: 3px;
			touch-action: manipulation;
			transition: all 0.2s;

			&[type=color] {
				background: $darkblue;
				border: 0;
				cursor: pointer;
				padding: 0;
			}

			&[type=text] {
				background-color: $offwhitetext;
				border-radius: 5px;
				border: none;
				color: $darkblue;
				cursor: text;
				padding: 7px;
				width: 97%;

				&:focus {
					outline: solid 1px #000000;
				}

				&::placeholder {
					color: $lightblue;
				}

				&:focus::placeholder {
					opacity: .5;
				}

				&[name=url] {
					font-size: 18px;
					padding: 5px;
				}
			}
		}
	}

	// This is for the Add Deck and Generate QR code buttons
	.buttonContainer {
		display: flex;
		align-items: center;
		flex-direction: row;
		margin-top: 10px;

		button {
			align-items: center;
			background: $offwhitetext;
			border-radius: 5px;
			border: solid 1px #000000;
			cursor: pointer;
			display: inline-flex;
			height: 30px;
			margin-right: 15px;
			min-width: 100px;

			image {
				float: left;
			}
		}
	}

	.subfields {
		align-items: flex-start;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		margin-top: 10px;
		min-width: 100%;
		margin-bottom: 10px;

		.commanderString {
			margin-right: 30px;
			flex-grow: .95;
		}
	}
}