@import '../style/variables';

main {
	align-items: center;
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
	margin-top: 2vh;

	@media only screen and (min-width: 901px) {
		width: 65vw;
	}

	@media only screen and (max-width: 900px) {
		width: 85vw;
	}
}