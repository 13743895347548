@import '../../style/variables';

footer {
	align-items: center;
	background-color: #192126;
	border-top: solid 1px #192126;
	color: #c7c7c7;
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	font-family: 'Synonym', sans-serif;
	font-size: 11px;
	justify-content: space-between;
	margin-top: 45px;
	min-height: 5vh;
	opacity: .9;
	width: 100vw;

	span {
		margin: 5px;
		padding: 20px;

		&.left {
			width: 20vw;
			margin-left: 10px;
		}

		&.right {
			width: 70vw;
			margin-right:10px;
			text-align: right;
		}
	}
}