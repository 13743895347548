$burntred: #4b2f2a;
$darkblue: #282c34;
$dropshadow: 10px 3px 3px #192126;
$lightblue: #5a667c;
$offwhitetext: #e8eaec;

// Color pallet based off the $darkblue color
$babyblue: #66E5FA;
$bloodorange: #C7533E;
$lightgray: #788194;
$peach: #FBBFA5;
